import { truncate } from "basikon-common-utils"
import React from "react"

const accessLog = {
  // <EntitiesPage/>
  pageConfig: {
    hideKpis: true,
    addButton: { hidden: true },
    tableExtended: true,
  },
  columns: [
    { title: "Date", name: "date", type: "datetime", timeFormat: "HH:mm:ss" },
    { title: "Status", name: "status", select: "accessLogStatus", badge: true },
    { title: "Method", name: "method" },
    { title: "URL", name: "url" },
    { title: "Server", name: "server" },
    { title: "Tenant", name: "tenant" },
    { title: "Username", name: "username" },
    { title: "Duration", name: "duration", type: "milliseconds" },
    { title: "Size", name: "size", type: "bytes" },
    { title: "Depth", name: "depth" },
  ],
  searchFields: [
    [
      { field: "server", colProps: { xs: 12, md: 3 }, regex: { fromStart: true, honorCase: true } },
      { field: "tenant", colProps: { xs: 12, md: 6 }, type: "multiple", select: "/api/core/tenants?list=true" },
      { field: "username", colProps: { xs: 12, md: 3 }, regex: "fromStart" },
    ],
    [
      { field: "status", select: "accessLogStatus", multiple: true, colProps: { xs: 12, md: 3 } },
      { field: "url", colProps: { xs: 12, md: 6 } },
      { field: "method", select: "httpMethod", multiple: true, colProps: { xs: 12, md: 3 } },
    ],
    [
      { field: "fromDate", type: "datetime", timeFormat: "HH:mm:ss", colProps: { xs: 12, md: 3 } },
      { field: "toDate", type: "datetime", timeFormat: "HH:mm:ss", colProps: { xs: 12, md: 3 } },
      { field: "minDuration", select: "accessLogDuration", creatable: true, colProps: { xs: 12, md: 3 } },
      { field: "maxDuration", select: "accessLogDuration", creatable: true, colProps: { xs: 12, md: 3 } },
    ],
    [
      { field: "sort", select: "accessLogSort", multiple: true, colProps: { xs: 12, md: 6 } },
      { field: "limit", type: "number", colProps: { xs: 12, md: 3 } },
    ],
  ],
  formatTableData,
}

function formatTableData(accessLogs) {
  const groupedAccessLogs = {}
  for (const accessLog of accessLogs) {
    const groupId = accessLog.requestId || "noRequest"
    groupedAccessLogs[groupId] = groupedAccessLogs[groupId] || []
    groupedAccessLogs[groupId].push(accessLog)

    if (accessLog.url?.length > 130) accessLog.url = <span title={accessLog.url}>{truncate(accessLog.url, 130)}</span>
  }

  return Object.keys(groupedAccessLogs).flatMap(groupId => {
    const groupAccessLogs = groupedAccessLogs[groupId]
    if (groupAccessLogs.length === 1) return groupAccessLogs

    let rootAccessLog
    const leafAccessLogs = []
    for (const groupAccessLog of groupAccessLogs) {
      if (!groupAccessLog.depth && !rootAccessLog) rootAccessLog = groupAccessLog
      else leafAccessLogs.push(groupAccessLog)
    }

    const mainAccessLog = groupAccessLogs.find(accessLog => !accessLog.depth)
    if (mainAccessLog) {
      return {
        ...mainAccessLog,
        children: {
          columns: accessLog.columns,
          data: leafAccessLogs,
        },
      }
    }
    return groupAccessLogs
  })
}

export default accessLog
