import axios from "axios"
import {
  isValidDate as commonUtilsIsValidDate,
  finance,
  formatDate,
  formatDateTime,
  formatDecimal,
  formatIban,
  formatPercentage,
  getCurrencySymbol,
  getDecimalSymbol,
  getEntityServerRoute,
  getFieldProps,
  isLessThanMin,
  isMoreThanMax,
  isObject,
  isValidUsername,
  momentDateFormat,
  removeAllAccents,
  replaceUrlParams,
  setFieldProps,
  unformatCurrency,
  unformatIban,
  validationRegExps,
} from "basikon-common-utils"
import { sanitize } from "dompurify"
import deepClone from "lodash.clonedeep"
import debounce from "lodash.debounce"
import get from "lodash.get"
import merge from "lodash.merge"
import marked from "marked"
import moment from "moment"
import React, { Suspense } from "react"
import { ButtonGroup, Col, ControlLabel, Dropdown, FormControl, FormGroup, InputGroup, Row } from "react-bootstrap"
import Datetime from "react-datetime"
import { Link } from "react-router-dom"
import Select, { components } from "react-select"
import AsyncSelect from "react-select/async"
import AsyncCreatableSelect from "react-select/async-creatable"
import CreatableSelect from "react-select/creatable"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import CustomButton from "@/_components/CustomButton"
import CustomCheckbox from "@/_components/CustomCheckbox"
import CustomRadio from "@/_components/CustomRadio"
import DebugPopover from "@/_components/DebugPopover"
import FormInputDatetime from "@/_components/FormInputDatetime"
import Slider from "@/_components/Slider"

import { hasFieldPropsChanged, isEmpty, syncFieldProps, toPatch } from "@/_services/inputUtils"
import { getRichValues, getValues } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { getConfigAtPath, getOptions, getTenant } from "@/_services/userConfiguration"
import {
  applyClasses,
  checkIfInIframe,
  checkRegistrationFormat,
  copyToClipboard,
  debug,
  DEFAULT_DEBOUNCE,
  fileToBase64,
  formatCurrency,
  getNavigationRef,
  getRandomInt,
  handleAccessibleOnKeyDown,
  isPromise,
  labelFromName,
  onDropdownMenuButtonKeyDown,
  onDropdownToggleKeyDown,
  openDocInTab,
  setBeforeUnloadListener,
} from "@/_services/utils"
import { setFormInputChangeHandler as setVirtualKeyboardChangeHandler } from "@/_services/virtual-keyboard"

const FormInputRichText = React.lazy(() => import("@/_components/FormInputRichText"))
const Switch = React.lazy(() => import("react-bootstrap-switch"))

const actionTypes = {
  SELECT_FILE: "selectFile",
  VIEW_FILE: "viewFile",
  DELETE_FILE: "deleteFile",
}

const dataDropdownIsOpen = "data-dropdown-is-open"

class FormInput extends React.Component {
  constructor(props) {
    super(props)

    const { field, modelPath = "" } = props
    const _modelPath = modelPath ? modelPath + "." : ""

    this.state = {
      keyedValue: null,
      modelFieldPath: _modelPath + field,
      debug,
      selectListIsLoaded: false,
      showDropBox: false,
      navigationRef: getNavigationRef(),
      renderMarkdown: true,
      // Some browsers like Chrome override the "off" autocomplete value.
      // So we need to provide a different value that does the same but that browsers don't override,
      // but still keep a standard value, otherwise accessibility tests don't pass.
      defaultAutoComplete: getOptions("browserAutofill") ? undefined : "one-time-code",
    }

    if (props.onSetState) {
      const debounceWait = this.getDebounceWait(props.debounce)
      if (debounceWait) this.onSetStateDebounced = debounce(props.onSetState, debounceWait, props.debounceOptions).bind(this)
    }
  }

  componentDidMount() {
    const { obj, field, mandatory, select, min, max } = this.props

    if (isPromise(select)) select.then(selectOptions => this.setState({ selectOptions }))
    syncFieldProps({ obj, field, mandatory, min, max })
  }

  componentDidUpdate(prevProps) {
    const { obj, field, mandatory, min, max } = this.props
    const { mandatory: prevMandatory, min: prevMin, max: prevMax } = prevProps
    const args = { obj, field, mandatory, min, max }
    if (mandatory !== prevMandatory || min !== prevMin || max !== prevMax || hasFieldPropsChanged(args)) syncFieldProps(args)
  }

  componentWillUnmount() {
    const { obj, field } = this.props
    syncFieldProps({ obj, field, mandatory: false, min: null, max: null })
  }

  handleChange = async event => {
    const {
      field: fieldName,
      label,
      type,
      timeFormat,
      select,
      noLodash,
      negative,
      obj,
      regExp,
      fileType,
      onSelect,
      mandatory,
      onSetState,
      debounceWait,
    } = this.getProps()
    const textLabel = !label && label !== "" ? labelFromName(fieldName) : loc(label)
    const parsedType = (type ? type : "text")?.toLowerCase()
    let patch = {}
    let value
    let errorFormat = null
    let keyedValue = event?.target?.value

    const { phoneRegExp, emailRegExp } = validationRegExps
    const locale = getLocale()
    const hasToBeNumberMessage = loc`${textLabel} has to be a number`

    if (["date", "datetime"].includes(parsedType)) {
      if (typeof event === "string") {
        keyedValue = event // not an event but a string
        value = null
        if (event !== "") errorFormat = loc`Invalid date`
      } else {
        const localDate = event ? event.toDate() : null // not an event but a moment
        if (timeFormat || parsedType === "datetime" || getConfigAtPath("disabledDateNoTime") === true) {
          value = localDate
        } else {
          // localDate is currently set at 0h0m0s, on current timezone "X".
          // The next line will change it to Xh0m0s on timezone X, which is equivalent to the UTC value
          value = new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()))
        }
      }
    } else if (select && parsedType !== "emails") {
      value = event ? event.value : null // not an event but a {value, label}
      if (parsedType && value !== null && value !== undefined) {
        if (parsedType === "number") {
          const digitRex = /^\d+$/
          if (!digitRex.test(value)) {
            errorFormat = hasToBeNumberMessage
          }
        } else {
          errorFormat = checkRegistrationFormat(parsedType, value)
        }
      }
      if (event && event.data) {
        for (const key of Object.keys(event.data)) {
          patch[key] = event.data[key]
        }
      }
      // Set this flag in patch when clicking on the new "creatable" option
      if (event?.__isNew__) patch._isNew = true
    } else if (parsedType === "number" || parsedType === "integer") {
      const hasToBeIntegerMessage = loc`${textLabel} has to be an integer`
      value = keyedValue || keyedValue === 0 ? unformatCurrency(keyedValue, locale) : keyedValue
      if (value === "") value = null
      if (negative) value = -value
      if (isNaN(value)) errorFormat = parsedType === "integer" ? hasToBeIntegerMessage : hasToBeNumberMessage
      else if (parsedType === "integer" && !Number.isInteger(value)) errorFormat = hasToBeIntegerMessage
    } else if (parsedType === "percentage") {
      const trimmedKeyValue = keyedValue?.endsWith("%") ? (keyedValue = keyedValue.substring(0, keyedValue.length - 1).trimRight()) : keyedValue
      const digitRex = /^[+-]?([0-9]+([.,][0-9]*)?|[.][0-9]+)$/
      //let digitRex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/
      if (trimmedKeyValue && !digitRex.test(trimmedKeyValue)) errorFormat = hasToBeNumberMessage
      value = keyedValue || keyedValue === 0 ? finance.ROUND(this.intlParseFloat(trimmedKeyValue) / 100, 10) : keyedValue

      if (value === "") value = null

      if (value && negative) value = -value
    } else if (parsedType === "currency") {
      value = unformatCurrency(keyedValue, locale)

      if (isNaN(value)) errorFormat = hasToBeNumberMessage

      if (negative) value = -value
    } else if (parsedType === "email") {
      value = keyedValue
      if (value && !emailRegExp.test(value)) errorFormat = loc`Email format should be "john@doe.com"`
    } else if (parsedType === "emails") {
      const validEmails = event.reduce((acc, val) => {
        if (val.value && emailRegExp.test(val.value)) acc.push(val.value)
        return acc
      }, [])
      if (validEmails.length !== event.length) errorFormat = loc`Email format should be "john@doe.com"`
      value = validEmails
    } else if (parsedType === "username") {
      value = keyedValue
      if (value) {
        const tenant = getTenant()
        if (!isValidUsername({ username: value, tenant })) {
          errorFormat = loc`Should be a valid email or a valid string ending with @${tenant}`
        }
      }
    } else if (parsedType === "phone") {
      value = keyedValue
      if (value) value = value.replace(/ /g, "")
      if (value && !phoneRegExp.test(value)) errorFormat = loc`Invalid format`
    } else if (parsedType === "checkbox") value = event.target.checked
    else if (parsedType === "radio") value = keyedValue
    else if (parsedType === "switch") value = event?.state?.value ?? false
    else if (parsedType === "file") {
      value = []
      keyedValue = Array.from(event.target.files)

      for (let i = 0; i < keyedValue.length; i++) {
        if (keyedValue[i]?.errorFormat) {
          errorFormat = keyedValue[i].errorFormat
          continue
        }

        const fileName = keyedValue[i].name

        const formData = new FormData()
        formData.append("file", keyedValue[i])
        formData.append("name", fileName)
        if (fileType) formData.append("type", fileType)
        value.push(formData)
      }
    } else if (parsedType === "iban") value = unformatIban(keyedValue)
    else {
      value = keyedValue === undefined ? event.value : keyedValue
      if (value) errorFormat = checkRegistrationFormat(parsedType, value, regExp)
    }

    if (fieldName?.includes(".") && !noLodash) patch = toPatch(obj, fieldName, value)
    else patch[fieldName] = value

    if (select && parsedType && parsedType !== "address" && value !== null && value !== undefined && onSelect && typeof onSelect === "function") {
      const onSelectPatch = await onSelect(merge(deepClone(obj), patch)) // Deep clone to avoid mutating "obj"
      if (onSelectPatch && isObject(onSelectPatch) && Object.keys(onSelectPatch).length) patch = merge(deepClone(patch), onSelectPatch)
    }

    let statePatch
    if (this.state.keyedValue !== keyedValue) statePatch = { keyedValue }

    updateFieldProps(patch, { obj, field: fieldName, mandatory, errorFormat })

    if (statePatch) this.setState(statePatch)

    if (onSetState && !debounceWait) onSetState(patch, event)
    else this.onSetStateDebounced?.(patch, event)

    setBeforeUnloadListener()
  }

  intlParseFloat = (input, locale) => {
    locale = locale || getLocale()
    let str = formatDecimal(1.1, locale)
    if (str.indexOf(",") >= 0) {
      // French format => replace "," by "." if no "." present (if there is a "." assume the French guy is inputing with . as a decimal separator)
      if (input.indexOf(".") === -1) input = input.replace(",", ".")
    }
    return Number(input)
  }

  handleBlur = (event, options = {}) => {
    // keyedValue: null is required so that the input is formatted after the user leaves the input.
    // It would be nice to find another of doing it because currently it causes a flicker between the previous and next value
    // when the user leaves the input immediately after updating it.
    // When the input is a select, there is no formatting to be done so we can skip this block,
    // which also prevents an accessibility tab navigation bug where the parent element is focused after this one
    // when tab navigating out of it.
    if (!options.isSelect) {
      const { keepValueOnBlur } = this.getProps()
      if (!keepValueOnBlur) this.setState({ keyedValue: null })
    }

    const { onBlur } = this.props
    if (typeof onBlur === "function") onBlur(event)
  }

  handleFocus = event => {
    const { onFocus } = this.props
    if (typeof onFocus === "function") onFocus(event)
  }

  getCurrencyOrLocale = () => {
    const { obj, currency } = this.getProps()
    return currency || obj?.currency || getLocale()
  }

  handleKeyDown = async event => {
    const { onEnter, onEscape, onPaste, onKeyDown } = this.props

    if (onKeyDown) return onKeyDown(event)

    const ignoreEnterKeyDown = event.target.type === "textarea"

    if (event.key === "Enter" && !ignoreEnterKeyDown) {
      event.preventDefault()
      if (onEnter) onEnter()
    } else if (event.key === "Escape") {
      event.preventDefault()
      if (onEscape) onEscape()
    }

    // 110 = key code for the numpad decimal separator
    // this is a different code from dot or comma from elsewhere on the keyboard
    if (event.keyCode === 110) {
      event.preventDefault()
      const decimalSymbol = getDecimalSymbol(getLocale())
      this.handleChange({ target: { value: event.target.value + decimalSymbol } })
    }

    if (onPaste && (event.metaKey || event.ctrlKey) && event.keyCode === 86) {
      const ta = event.target
      const str = await onPaste(event)
      if (str) {
        if (!ta.value) ta.value = str
        else if (ta.selectionStart > 0 && ta.selectionEnd > 0) {
          ta.value = ta.value.substr(0, ta.selectionStart) + str + ta.value.substr(ta.selectionEnd)
        }
        // did not succeed to triger a change event => call handle change directly (hack)
        // const ev2 = new Event('input', { bubbles: true});
        // ta.dispatchEvent(ev2);
        this.handleChange({ target: { value: ta.value } })
      }
    }
  }

  linkFunction = ({ event, labelLinkTo, labelLinkToTarget }) => {
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target
    const { navigationRef } = this.state
    event.preventDefault()

    if (checkIfInIframe() || labelLinkToTarget === "_self") {
      navigationRef.history.push(labelLinkTo)
      return
    }

    if (labelLinkTo.startsWith("data")) {
      const openedWindow = window.open()
      openedWindow.document.write(
        '<iframe src="' +
          labelLinkTo +
          '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
      )
      return
    }

    const target = labelLinkToTarget || "_blank"
    if (labelLinkTo.startsWith("http")) {
      window.open(labelLinkTo, target)
      return
    }

    window.open(`${window.location.origin}${labelLinkTo}`, target)
  }

  getLabel() {
    const {
      label,
      labelLinkTo,
      labelLinkToIcon,
      labelLinkToStyle,
      labelLinkToTarget,
      field,
      mandatory,
      showMandatoryHint = mandatory,
    } = this.getProps()
    if (React.isValidElement(label)) return label

    // support the case where label is forced to ""
    let textLabel = !label && label !== "" ? labelFromName(field) : loc(label)
    if (labelLinkTo) {
      const icon = (
        <i
          className={`${labelLinkToIcon || "icn-external-link icn-xs"} text-${labelLinkToStyle || "link"} c-pointer`}
          onClick={event => this.linkFunction({ event, labelLinkTo, labelLinkToTarget })}
        />
      )
      textLabel = (
        <>
          <ControlLabel>{textLabel}</ControlLabel>
          {showMandatoryHint && (
            <span className="form-input-mandatory-hint text-danger">
              <i className="icn-asterisk icn-xxxs relative bottom-5px" />
            </span>
          )}
          {labelLinkTo ? (
            <a href={labelLinkTo} aria-label={`${loc("Link to")} ${labelLinkTo}`} className="ml-5px">
              {icon}
            </a>
          ) : (
            icon
          )}
        </>
      )
    }
    return textLabel
  }

  getPlaceholder() {
    const { placeholder } = this.getProps()
    return placeholder ? loc(placeholder) : /*typeof label === "string" ? label :*/ undefined
  }

  getProps = () => {
    const { field, obj = {} } = this.props

    const configProps = getFieldProps(obj, field)
    const props = { ...this.props, ...configProps }

    props.debounceWait = this.getDebounceWait(props.debounce)

    return props
  }

  getDebounceWait = debounce => {
    if (debounce === true) return DEFAULT_DEBOUNCE
    if (typeof debounce === "number") return debounce
  }

  getControlLabelWithDebug = ({ clClassName = "" } = {}) => {
    const { modelFieldPath } = this.state
    const {
      showLabel = true,
      help,
      debugUrlQuery,
      debugScriptList,
      controlLabelClassName = "",
      select,
      obj,
      field,
      mandatory,
      showMandatoryHint = mandatory,
      labelLinkTo,
    } = this.getProps()
    const label = this.getLabel()

    const controlLabelWithDebug = (
      <>
        {showLabel && label}
        {showMandatoryHint && !labelLinkTo && (
          <span className="form-input-mandatory-hint text-danger">
            <i className="icn-asterisk icn-xxxs relative bottom-5px" />
          </span>
        )}
        {debug && (
          <DebugPopover
            help={help}
            debug={debug}
            select={select}
            debugUrlQuery={debugUrlQuery}
            value={select && obj?.[field]}
            modelFieldPath={modelFieldPath}
            debugScriptList={debugScriptList}
          />
        )}
      </>
    )

    return React.isValidElement(label) || !showLabel ? (
      controlLabelWithDebug
    ) : (
      <ControlLabel className={applyClasses({ [controlLabelClassName]: true, [clClassName]: true })} htmlFor={modelFieldPath}>
        {controlLabelWithDebug}
      </ControlLabel>
    )
  }

  readOnlyControlInArrayOrInForm = ({ label, control, linkTo, addClassName, helperDisplay }) => {
    const { modelFieldPath } = this.state
    const props = this.getProps()
    const {
      obj,
      colProps,
      inArray,
      openLinkInNewTab,
      rightAddOn,
      inline, // legacy, use displayMode: "inline" instead
      formGroupClassName = "",
      formGroupStyle = {},
      action,
      actionClassName = "",
      actionIcon,
      actionTooltip = "",
      readOnlyAction,
      readOnly,
      displayMode,
      handleClear,
    } = props
    let { fcStaticClassName = "" } = props

    const ControlLabelWithDebug = this.getControlLabelWithDebug

    if (addClassName) {
      if (fcStaticClassName) fcStaticClassName += " "
      fcStaticClassName += addClassName
    }

    if (linkTo) {
      control = (
        <Link to={replaceUrlParams(linkTo, obj)} target={linkTo.startsWith("http") || openLinkInNewTab ? "_blank" : undefined}>
          {control}
        </Link>
      )
    }

    if (inArray) {
      if (fcStaticClassName) control = <span className={fcStaticClassName}>{control}</span>
      return (
        <>
          {rightAddOn && typeof rightAddOn === "string" ? control + ` ${rightAddOn}` : control || ""}
          {helperDisplay}
        </>
      )
    }

    if (inline || displayMode === "inline") {
      return (
        <Row>
          <Col xs={4} sm={3} md={2} lg={2}>
            <ControlLabelWithDebug clClassName="leading-3-75" />
          </Col>
          <Col xs={8} sm={9} md={10} lg={10}>
            <FormControl.Static id={modelFieldPath} aria-label={label} className={fcStaticClassName}>
              {control}
            </FormControl.Static>
            {helperDisplay}
          </Col>
        </Row>
      )
    }

    if (readOnly) {
      const label = this.getLabel()

      if (displayMode === "badge") {
        return (
          <span className="read-only-badge min-h-24px border-1px br-theme border-solid border-gray-lighter font-1-2 leading-2-4 mr-5px mt-5px pdr-5px inline-flex flex-align-center flex-wrap">
            <span className="flex flex-wrap">
              {typeof handleClear === "function" && (
                <div
                  className="inline-flex-center min-h-24px min-w-24px text-danger c-pointer"
                  tabIndex="0"
                  onClick={handleClear}
                  onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: handleClear })}
                >
                  <i className="icn-xmark icn-xxs" />
                </div>
              )}
              <span className="font-weight-bold mr-5px">{label}:</span>
            </span>
            {control}
          </span>
        )
      }

      if (displayMode === "raw") {
        return (
          <>
            {label && <span className="font-weight-bold mr-5px">{label}:</span>}
            <span>{control}</span>
          </>
        )
      }
    }

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        <ControlLabelWithDebug />
        <FormControl.Static
          id={modelFieldPath}
          aria-label={label}
          className={`${fcStaticClassName}${readOnlyAction && action ? " form-control-static-action" : ""}`}
        >
          {control}
          {readOnlyAction && action && (
            <span className={"c-pointer " + actionClassName} onClick={action} onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: action })}>
              <ButtonWithTooltip btnClassName="m-0 pd-0" bsStyle="default" className={actionIcon || "icn-search icn-sm"} tooltip={actionTooltip} />
            </span>
          )}
        </FormControl.Static>
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  datetimeInput = ({ parsedValue, errorDisplay, helperDisplay, ControlLabelWithDebug }) => {
    const { modelFieldPath, keyedValue, defaultAutoComplete } = this.state
    const props = this.getProps()
    const {
      colProps,
      autoComplete = defaultAutoComplete,
      inArray,
      formGroupClassName = "",
      formGroupStyle = {},
      readOnly,
      disabled,
      showDatetimeInput = true,
      fcClassName = "",
      dateFormat,
      timeFormat,
      isValidDate,
      splitTimeControl = false,
      dropdownMinutesInterval = 30,
    } = props
    const locale = getLocale()

    const format = dateFormat || momentDateFormat(locale)
    if (readOnly || disabled) {
      // const dispValue = parsedValue ? (commonUtilsIsValidDate(parsedValue) ? formatDateTime(parsedValue, locale) : parsedValue.toString()) : "" // this code is to avoid errors when data is wrong
      const dispValue = parsedValue
        ? commonUtilsIsValidDate(parsedValue)
          ? moment(parsedValue).format(format + " " + (timeFormat || "H:mm"))
          : parsedValue.toString()
        : "" // this code is to avoid errors when data is wrong
      if (inArray) return dispValue

      const formInput = (
        <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle}>
          <ControlLabelWithDebug />
          <InputGroup data-disabled>
            <InputGroup.Addon className="form-input-addon">
              <i className="icn-calendar icn-sm" />
            </InputGroup.Addon>
            <div className="form-control" id={modelFieldPath} disabled>
              {dispValue}
            </div>
          </InputGroup>
        </FormGroup>
      )

      return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
    }

    const inputValue = keyedValue ?? parsedValue ?? ""
    const control = (
      <FormInputDatetime
        closeOnSelect
        ControlLabelWithDebug={ControlLabelWithDebug}
        input={showDatetimeInput}
        className={fcClassName}
        dateFormat={format}
        timeFormat={timeFormat || "H:mm"}
        splitTimeControl={splitTimeControl}
        dropdownMinutesInterval={dropdownMinutesInterval}
        autoComplete={autoComplete}
        inputProps={{
          placeholder: props.placeholder || format,
          disabled,
          onChange: event => {
            const dateFromValue = event.target.value
            const formattedDate = moment(dateFromValue, format, true)
            if (!formattedDate.isValid()) this.handleChange(dateFromValue)
          },
          onKeyDown: event => {
            if (["Enter", "Tab"].includes(event.key) && event?.target?.value && format && errorDisplay) {
              const formattedDate = moment(event.target.value, format)
              if (formattedDate.isValid()) this.handleChange(formattedDate)
            }
          },
          onKeyUp: event => {
            const dateFromValue = event?.target?.value
            if (event.key.match(/[0-9]/g) && dateFromValue && format) {
              const monthIndex = format?.indexOf("MM") ?? -1
              if (monthIndex !== -1) {
                const monthFormat = format.substring(monthIndex + 2, monthIndex + 3)
                const regex = new RegExp(`${monthFormat}`, "g")
                if (format.charAt(dateFromValue.length) === monthFormat && (dateFromValue.match(regex)?.length ?? 0) < 2)
                  this.handleChange(dateFromValue + monthFormat)
              }
            } else if (dateFromValue && format && dateFromValue.length > 1 && event.key === dateFromValue.charAt(dateFromValue.length - 2)) {
              this.handleChange(dateFromValue.substring(0, dateFromValue.length - 1))
            }
          },
          value: commonUtilsIsValidDate(inputValue) ? moment(inputValue).format(format) : inputValue,
        }}
        locale={locale?.split("-")[1]}
        value={inputValue}
        onChange={dateFromValue => {
          if (typeof dateFromValue === "object" && dateFromValue?._isAMomentObject && dateFromValue.isValid()) this.handleChange(dateFromValue)
        }}
        // onClick={event => setVirtualKeyboardChangeHandler(event , this.handleChange)} // doesn't work
        isValidDate={isValidDate}
        renderView={(_, renderDefault) => (
          <>
            {renderDefault()}
            <CustomButton
              bsStyle="primary"
              fill
              bsSize="small"
              pullRight
              onClick={() => this.handleChange(moment(new Date()))}
            >{loc`Today`}</CustomButton>
          </>
        )}
      />
    )

    if (inArray) {
      return (
        <>
          {control}
          {errorDisplay}
          {helperDisplay}
        </>
      )
    }

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        {!splitTimeControl && <ControlLabelWithDebug />}
        {control}
        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  dateInput = ({ parsedValue, errorDisplay, helperDisplay, ControlLabelWithDebug }) => {
    const { modelFieldPath, keyedValue, defaultAutoComplete } = this.state
    const props = this.getProps()
    const {
      fcClassName = "",
      readOnly,
      displayMode,
      showDateInput = true,
      isValidDate,
      colProps,
      autoComplete = defaultAutoComplete,
      inArray,
      formGroupClassName = "",
      formGroupStyle = {},
      isBirthDate,
      dateFormat,
      timeFormat,
      handleClear,
    } = props
    const locale = getLocale()
    const disabled = props.disabled ? true : false

    // "disabled" is same as "readOnly" in the case of a date in order to keep the same format
    // because <Datetime/> does not have the same format as formatDate() even if they have the same locale
    let format = dateFormat || momentDateFormat(locale)
    const dateFormatLength = format.length
    if (timeFormat) format = format + " - HH:mm"
    if (readOnly || disabled) {
      // const dispValue = parsedValue ? formatDate(parsedValue, locale) : ""
      const dispValue = parsedValue ? moment(parsedValue).format(format) : ""
      if (inArray) {
        return typeof dispValue === "object"
          ? dispValue.toString() // Do not render object to avoid breaking the page rendering
          : dispValue
      }

      if (readOnly) {
        const label = this.getLabel()

        if (displayMode === "badge") {
          return (
            <span className="read-only-badge min-h-24px border-1px br-theme border-solid border-gray-lighter font-1-2 leading-2-4 mr-5px mt-5px pdr-5px inline-flex flex-align-center flex-wrap">
              <span className="flex flex-wrap">
                {typeof handleClear === "function" && (
                  <div
                    className="inline-flex-center max-h-24px min-h-24px max-w-24px min-w-24px text-danger c-pointer"
                    tabIndex="0"
                    onClick={handleClear}
                    onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: handleClear })}
                  >
                    <i className="icn-xmark icn-xxs" />
                  </div>
                )}
                <span className="font-weight-bold mr-5px">{label}:</span>
              </span>
              {dispValue}
            </span>
          )
        }

        if (displayMode === "raw") {
          return (
            <>
              {label && <span className="font-weight-bold mr-5px">{label}:</span>}
              <span>{dispValue}</span>
            </>
          )
        }
      }

      const formInput = (
        <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
          <ControlLabelWithDebug />
          <InputGroup data-disabled>
            <InputGroup.Addon className="form-input-addon">
              <i className="icn-calendar icn-sm" />
            </InputGroup.Addon>
            <div className="form-control" id={modelFieldPath} disabled>
              {dispValue}
            </div>
          </InputGroup>
          {helperDisplay}
        </FormGroup>
      )

      return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
    }

    const viewDate = isBirthDate && !(keyedValue || parsedValue) ? new Date(Date.UTC(2000, 0, 1)) : keyedValue || parsedValue
    const inputValue = keyedValue ?? parsedValue ?? ""

    const inputProps = {
      placeholder: props.placeholder || format,
      disabled,
      onChange: event => {
        const dateFromValue = event.target.value
        const formattedDate = moment(dateFromValue, format, true)
        if (!formattedDate.isValid()) this.handleChange(dateFromValue)
        else if (timeFormat) this.handleChange(formattedDate)
      },
      onKeyDown: event => {
        if (["Enter", "Tab"].includes(event.key) && event?.target?.value && format && errorDisplay) {
          const formattedDate = moment(event.target.value, format)
          if (formattedDate.isValid()) this.handleChange(formattedDate)
        }
      },
      onKeyUp: event => {
        const dateFromValue = event?.target?.value
        if (event.key.match(/[0-9]/g) && dateFromValue && format) {
          const monthIndex = format?.indexOf("MM") ?? -1
          if (monthIndex !== -1) {
            const monthFormat = format.substring(monthIndex + 2, monthIndex + 3)
            const regex = new RegExp(`${monthFormat}`, "g")
            if (format.charAt(dateFromValue.length) === monthFormat && (dateFromValue.match(regex)?.length ?? 0) < 2) {
              this.handleChange(dateFromValue + monthFormat)
            } else if (
              timeFormat &&
              dateFromValue.length === dateFormatLength &&
              format.charAt(dateFromValue.length) === " " &&
              (dateFromValue.match(regex)?.length ?? 0) === 2 &&
              !dateFromValue.match(new RegExp(`${" "}`, "g"))
            ) {
              this.handleChange(dateFromValue + " - ")
            } else if (
              timeFormat &&
              dateFromValue.length === dateFormatLength + 5 &&
              format.charAt(dateFromValue.length) === ":" &&
              dateFromValue.match(new RegExp(`${" - "}`, "g"))
            ) {
              this.handleChange(dateFromValue + ":")
            }
          }
        } else if (dateFromValue && format && dateFromValue.length > 1 && event.key === dateFromValue.charAt(dateFromValue.length - 2)) {
          this.handleChange(dateFromValue.substring(0, dateFromValue.length - 1))
        }
      },
      value: commonUtilsIsValidDate(inputValue) ? moment(inputValue).format(format) : inputValue,
    }

    if (!inputValue) inputProps.value = ""

    const control = (
      <Datetime
        closeOnSelect
        input={showDateInput}
        className={fcClassName}
        dateFormat={dateFormat || momentDateFormat(locale)}
        timeFormat={timeFormat ? "H:mm" : false}
        viewDate={viewDate}
        autoComplete={autoComplete}
        inputProps={inputProps}
        locale={locale?.split("-")[1]}
        value={inputValue}
        onChange={dateFromValue => {
          if (typeof dateFromValue === "object" && dateFromValue?._isAMomentObject && dateFromValue.isValid()) this.handleChange(dateFromValue)
        }}
        // onClick={event => setVirtualKeyboardChangeHandler(event , this.handleChange)} // doesn't work
        isValidDate={isValidDate}
        renderView={(_, renderDefault) => (
          <>
            {renderDefault()}
            <CustomButton
              bsStyle="primary"
              fill
              bsSize="small"
              pullRight
              onClick={() => this.handleChange(moment(new Date()))}
            >{loc`Today`}</CustomButton>
          </>
        )}
      />
    )

    if (inArray) {
      return (
        <>
          {control}
          {errorDisplay}
          {helperDisplay}
        </>
      )
    }

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        <ControlLabelWithDebug />
        {control}
        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  selectInput = ({ parsedValue, errorDisplay, helperDisplay, placeholder, ControlLabelWithDebug }) => {
    const { modelFieldPath, selectOptions: selectOptionsState, defaultAutoComplete, keyedValue } = this.state
    const props = this.getProps()
    const {
      obj,
      select,
      filterOption,
      action,
      actionIcon,
      actionTooltip = "",
      actionClassName = "",
      debounceWait,
      debounceOptions,
      creatable,
      readOnly,
      colProps,
      showCopy,
      hideChevron,
      onKeyDown,
      onInputChange,
      autoComplete = defaultAutoComplete,
      inArray,
      formGroupClassName = "",
      formGroupStyle = {},
      buttonGroupClassName = "",
      defaultOptions = true,
      hideClear,
      help,
      richValuesList,
      autoFocus,
      button,
      splitButton,
      filteredValues,
      onSelect,
      openMenuOnClick,
      onClick,
      noOptionsMessage = () => loc("No data"),
      loadingMessage = () => loc("Loading"),
      fcClassName = "",
      linkTo,
      rightAddOn,
      badge,
      bsStyle,
      bsSize,
      label, // for the select input the label comes from the props, not the args, which is weird
      pullRight,
      showUnknownLabelHint = true,
      keepValueOnBlur,
      isLoading,
    } = props
    const disabled = props.disabled ? true : false
    let selectOptions = selectOptionsState
    let { selectListIsLoaded } = this.state

    if (typeof select === "function") {
      // autocompletion
      if (readOnly) {
        return this.readOnlyControlInArrayOrInForm({ control: parsedValue || "", linkTo, helperDisplay })
      }

      let loadOptions
      if (!debounceWait) loadOptions = select
      else {
        const func = (query, callback) => {
          if (query?.length === 1) return // Ignore when having 1 character // this will fix the cursor problem when deleting the last character
          return select(query).then(resp => callback(resp))
        }
        loadOptions = debounce(func, debounceWait, debounceOptions)
      }

      const formControl = creatable ? (
        <AsyncCreatableSelect
          id={modelFieldPath}
          data-model-field-path={modelFieldPath}
          loadOptions={loadOptions}
          isDisabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onChange={this.handleChange}
          onBlur={event => this.handleBlur(event, { isSelect: true })}
          onFocus={this.handleFocus}
          // onClick={() => setVirtualKeyboardChangeHandler(this.handleChange, "")} // doesn't work
          defaultOptions={defaultOptions}
          className="select-group"
          classNamePrefix="select"
          openMenuOnClick={openMenuOnClick}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          formatCreateLabel={text => `${loc`Create`} "${text}"`}
          value={parsedValue ? { parsedValue, label: parsedValue } : null}
          components={getSelectComponents({ isClearable: !hideClear, showCopy, hideChevron, isLoading })}
          aria-labelledby={modelFieldPath}
        />
      ) : (
        <AsyncSelect
          id={modelFieldPath}
          autoFocus={autoFocus}
          data-model-field-path={modelFieldPath}
          loadOptions={loadOptions}
          isDisabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onBlur={event => this.handleBlur(event, { isSelect: true })}
          onFocus={this.handleFocus}
          onKeyDown={onKeyDown}
          onChange={this.handleChange}
          // onClick={() => setVirtualKeyboardChangeHandler(this.handleChange, "")} // doesn't work
          onInputChange={onInputChange}
          defaultOptions={defaultOptions}
          className="select-group"
          classNamePrefix="select"
          openMenuOnClick={openMenuOnClick}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          value={parsedValue && typeof parsedValue === "string" ? { parsedValue, label: parsedValue } : null}
          components={getSelectComponents({ isClearable: !hideClear, showCopy, hideChevron, isLoading })}
          aria-labelledby={modelFieldPath}
        />
      )

      if (action) {
        if (inArray) {
          return (
            <InputGroup
              className="form-input-select-action-in-array" // DOM hint
            >
              <InputGroup.Addon
                className={"c-pointer " + actionClassName}
                onClick={action}
                onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: action })}
              >
                <ButtonWithTooltip btnClassName="m-0 pd-0" bsStyle="default" className={actionIcon || "icn-icons icn-sm"} tooltip={actionTooltip} />
              </InputGroup.Addon>
              {formControl}
              <DebugPopover debug={debug} help={help} modelFieldPath={modelFieldPath} />
            </InputGroup>
          )
        }

        const formInput = (
          <FormGroup
            bsClass={`form-group ${formGroupClassName}`}
            className="form-input-select-action" // DOM hint
            style={formGroupStyle}
            data-model-field-path={modelFieldPath}
          >
            <ControlLabelWithDebug />
            <InputGroup>
              {formControl}
              <InputGroup.Addon
                className={"c-pointer " + actionClassName}
                onClick={action}
                onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: action })}
              >
                <ButtonWithTooltip btnClassName="m-0 pd-0" bsStyle="default" className={actionIcon || "icn-icons icn-sm"} tooltip={actionTooltip} />
              </InputGroup.Addon>
            </InputGroup>
            {errorDisplay}
            {helperDisplay}
          </FormGroup>
        )

        return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
      }

      if (inArray) {
        return (
          <>
            {formControl}
            {errorDisplay}
            {helperDisplay}
          </>
        )
      }

      const formInput = (
        <FormGroup
          bsClass={`form-group ${formGroupClassName}`}
          className="form-input-select-function" // DOM hint
          style={formGroupStyle}
          data-model-field-path={modelFieldPath}
        >
          <ControlLabelWithDebug />
          {formControl}
          {errorDisplay}
          {helperDisplay}
        </FormGroup>
      )

      return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
    }

    if (Array.isArray(select)) {
      const locale = getLocale()

      selectOptions = select.map(it => {
        if (typeof it === "string" || typeof it === "number" || it instanceof Date) {
          const label = it instanceof Date ? formatDate(it, locale) : it
          return { value: it, label: rightAddOn ? `${label} ${rightAddOn}` : label }
        }
        if (it?.label) {
          if (rightAddOn) it.label += ` ${rightAddOn}`
          if (typeof it.label === "string") it.label = it.isTranslated === false ? it.label : loc(it.label)
          return it
        }
        return { ...it, label: rightAddOn ? `${it?.value} ${rightAddOn}` : it?.value }
      })
    } else if (typeof select === "string") {
      // A special way of doing things here : the functions getRichValues and getValues
      // return nothing when the list is being fetched but allow for a callback when they are.
      // Here the render of this component is triggered from within the render (which in general must be avoided)
      // by the callback, and at the second render the functions would return the values instead.
      const _getValues = richValuesList ? getRichValues : getValues
      selectOptions = _getValues(replaceUrlParams(select, obj), () => this.setState({ selectListIsLoaded: true })) // get list from server (reference table)
    }

    // Dynamic lists are handled earlier so if upon reaching this code
    // there is no selectOptions then the animation loaded will stay displayed.
    // However this is more a configuration bug than a coding one, so we don't support this case.
    if (typeof select !== "string" || selectOptions?.length > 0) selectListIsLoaded = true
    selectOptions = selectOptions || []

    if (Array.isArray(filteredValues)) {
      selectOptions = selectOptions.filter(selectionOption => filteredValues.includes(selectionOption.value))
    }

    let selectOption
    if (parsedValue instanceof Date) selectOption = selectOptions.find(item => item.value.getTime?.() === parsedValue.getTime())
    else selectOption = selectOptions.find(item => item.value === parsedValue)

    if (!selectOption && parsedValue && typeof parsedValue !== "object") {
      selectOptions = [
        ...selectOptions,
        {
          value: parsedValue,
          label: creatable ? parsedValue : showUnknownLabelHint ? `${parsedValue} (???)` : parsedValue,
        },
      ]
    }

    if (readOnly) {
      if (badge && selectOption) {
        const badge = (
          <CustomButton
            className={fcClassName + (onClick ? "" : " c-default pointer-events-none") + " btn-badge"}
            bsStyle={selectOption.style || "info"}
            bsSize="xs"
            fill
            round
            onClick={onClick}
            tabIndex={onClick ? "0" : "-1"}
          >
            {selectOption.label}
          </CustomButton>
        )

        const formInput = label ? (
          <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
            <ControlLabelWithDebug />
            <br />
            {badge}
          </FormGroup>
        ) : (
          badge
        )

        return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
      }

      return this.readOnlyControlInArrayOrInForm({
        control:
          (parsedValue &&
            (selectOption ? (
              selectOption.label
            ) : selectListIsLoaded ? (
              showUnknownLabelHint ? (
                `${parsedValue} (???)`
              ) : (
                parsedValue
              )
            ) : (
              <i className="icn-circle-notch icn-sm icn-spin font-theme text-gray" />
            ))) ||
          "",
        linkTo,
        helperDisplay,
      })
    }

    if (creatable) {
      const keepInputProps = keepValueOnBlur
        ? {
            onInputChange: (value, { action }) => {
              const warningFormat = value ? loc`Caution, the value is incomplete and will not be considered.` : undefined
              if (action === "input-change")
                this.setState({
                  keyedValue: value,
                  warningFormat,
                })
              else if (action === "set-value")
                this.setState({
                  keyedValue: "",
                  warningFormat,
                })
            },
            inputValue: keyedValue,
          }
        : {}
      let itemValue = selectOptions.filter(option => option.value === parsedValue)
      let selectControl = (
        <CreatableSelect
          id={modelFieldPath}
          className={"select-group " + (fcClassName || "")}
          classNamePrefix="select"
          placeholder={placeholder}
          autoComplete={autoComplete}
          value={itemValue}
          options={selectOptions}
          onChange={this.handleChange}
          {...keepInputProps}
          onBlur={event => this.handleBlur(event, { isSelect: true })}
          onFocus={this.handleFocus}
          // onClick={() => setVirtualKeyboardChangeHandler(this.handleChange, "")} // doesn't work
          formatCreateLabel={text => `${loc`Create`} "${text}"`}
          isDisabled={disabled || readOnly}
          openMenuOnClick={openMenuOnClick}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          components={getSelectComponents({ isClearable: !hideClear, showCopy, hideChevron, isLoading })}
          filterOption={filterOption || filterOptionFunction}
          aria-labelledby={modelFieldPath}
        />
      )

      // watch carefully here: the selectControl variable is overriden
      // but its previous value is used just before
      if (action) {
        selectControl = (
          <InputGroup>
            <InputGroup.Addon
              className={"c-pointer " + actionClassName}
              onClick={action}
              onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: action })}
            >
              <ButtonWithTooltip btnClassName="m-0 pd-0" bsStyle="default" className={actionIcon || "icn-icons icn-sm"} tooltip={actionTooltip} />
            </InputGroup.Addon>
            {selectControl}
          </InputGroup>
        )
      }

      if (inArray) {
        return (
          <>
            {selectControl}
            {errorDisplay}
            {helperDisplay}
          </>
        )
      }

      const formInput = (
        <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
          <ControlLabelWithDebug />
          {selectControl}
          {errorDisplay}
          {helperDisplay}
        </FormGroup>
      )

      return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
    }

    let selectedOption
    if (parsedValue instanceof Date) selectedOption = selectOptions.find(option => option.value.getTime?.() === parsedValue.getTime())
    else selectedOption = selectOptions.find(option => option.value === parsedValue)

    const id = `[id="${modelFieldPath}"]`
    const isLoadingList = isLoading || ((parsedValue || parsedValue === 0 ? true : false) && !selectListIsLoaded)
    const dropdownLabel = splitButton ? selectedOption?.label || (showUnknownLabelHint ? `${parsedValue} ???` : parsedValue) : ""
    const selectControl = (
      <>
        {button && (
          // this div is important to get buttons under the label
          <div
            className={
              "form-input-select-button " + // DOM hint
              (pullRight ? "pull-right" : "")
            }
          >
            <ButtonGroup
              bsClass={"btn-group " + buttonGroupClassName}
              className={disabled || readOnly ? "disabled" : ""}
              data-model-field-path={modelFieldPath}
            >
              {selectOptions.map((item, key) => {
                const isSelected = item.value || item.value === 0 ? item.value === parsedValue : key === 1
                return (
                  <CustomButton
                    key={item.value + key}
                    disabled={disabled || readOnly}
                    bsStyle={disabled || readOnly ? "default" : bsStyle || "primary"}
                    bsSize={bsSize === "default" ? undefined : bsSize || "small"}
                    fill={isSelected}
                    onClick={() => this.handleChange({ value: isSelected ? undefined : item.value })}
                    className={(item.className || "") + (isSelected ? " selected" : "")}
                  >
                    {item.icon && <i className={item.icon} />}
                    {item.labelHtml ? (
                      <span dangerouslySetInnerHTML={{ __html: sanitize(item.labelHtml) }} />
                    ) : typeof item.label === "string" ? (
                      loc(item.label)
                    ) : (
                      item.label
                    )}
                  </CustomButton>
                )
              })}
              {action && !disabled && !readOnly && (
                <CustomButton
                  key="search"
                  simple
                  className="inline-flex-center"
                  bsStyle={disabled || readOnly ? "default" : "primary"}
                  onClick={action}
                  onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: action })}
                >
                  <ButtonWithTooltip
                    btnClassName="m-0 pd-0"
                    bsStyle="default"
                    className={actionIcon || "icn-search icn-sm"}
                    tooltip={actionTooltip}
                  />
                </CustomButton>
              )}
            </ButtonGroup>
          </div>
        )}

        {splitButton && (
          <Dropdown
            id={modelFieldPath}
            className="form-input-select-split-button" // DOM hint
            bsSize="xs"
            onClick={event => {
              const { target } = event
              target.focus()
              target.nextSibling?.click()
            }}
            onToggle={value => {
              const el = document.querySelector(id)
              el.setAttribute(dataDropdownIsOpen, value)
            }}
          >
            <CustomButton bsStyle={selectedOption?.style || "primary"} fill tabIndex="-1">
              {dropdownLabel}
            </CustomButton>
            <Dropdown.Toggle
              bsStyle={`${selectedOption?.style || "primary"} btn-fill`}
              onKeyDown={onDropdownToggleKeyDown}
              aria-label={dropdownLabel}
            />
            <Dropdown.Menu className="br-theme overflow-hidden border-0">
              {selectOptions
                .filter(option => option.value !== parsedValue)
                .map((option, key) => (
                  <CustomButton
                    key={key}
                    bsStyle={selectedOption?.style || "primary"}
                    bsSize="small"
                    fill
                    className="btn-simple white-space-normal w-100 text-left br-0 m-0 inline-flex align-items-center min-w-200px"
                    onClick={event => {
                      event.stopPropagation()
                      this.handleChange({ value: option.value })
                    }}
                    onKeyDown={onDropdownMenuButtonKeyDown}
                  >
                    {option.label}
                  </CustomButton>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        )}

        {!button && !splitButton && (
          <Select
            id={modelFieldPath}
            aria-labelledby={modelFieldPath}
            className={"select-group " + (fcClassName || "")}
            classNamePrefix="select"
            autoComplete={autoComplete}
            placeholder={placeholder || loc("Select") + "..."}
            value={
              isLoadingList
                ? ""
                : selectOptions.filter(o => (parsedValue instanceof Date ? o.value.getTime?.() === parsedValue.getTime() : o.value === parsedValue))
            }
            options={selectOptions}
            isDisabled={disabled || readOnly}
            onChange={this.handleChange}
            onSelect={onSelect}
            // onClick={() => setVirtualKeyboardChangeHandler(this.handleChange, "")} // doesn't work
            openMenuOnClick={openMenuOnClick}
            noOptionsMessage={noOptionsMessage}
            loadingMessage={loadingMessage}
            components={getSelectComponents({ isClearable: !hideClear, showCopy, hideChevron, isLoading: isLoadingList })}
            filterOption={filterOption || filterOptionFunction}
            onBlur={event => {
              const el = document.querySelector(id)
              el.setAttribute(dataDropdownIsOpen, false)
              this.handleBlur(event, { isSelect: true })
            }}
            onFocus={event => {
              const el = document.querySelector(id)
              el.setAttribute(dataDropdownIsOpen, true)
              this.handleFocus(event)
            }}
            tabSelectsValue={false}
          />
        )}
      </>
    )

    if (inArray) {
      return (
        <>
          <DebugPopover debug={debug} help={help} modelFieldPath={modelFieldPath} select={select} />
          {colProps ? <Col {...colProps}>{selectControl}</Col> : selectControl}
          {errorDisplay}
          {helperDisplay}
        </>
      )
    }

    if (action) {
      let formInput
      if (button) {
        // action is already handled
        formInput = (
          <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
            <ControlLabelWithDebug />
            {selectControl}
            {errorDisplay}
            {helperDisplay}
          </FormGroup>
        )
      } else {
        formInput = (
          <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
            <ControlLabelWithDebug />
            <InputGroup>
              {selectControl}
              <InputGroup.Addon
                className={"c-pointer " + actionClassName}
                onClick={action}
                onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: action })}
              >
                <ButtonWithTooltip btnClassName="m-0 pd-0" bsStyle="default" className={actionIcon || "icn-icons icn-sm"} tooltip={actionTooltip} />
              </InputGroup.Addon>
            </InputGroup>
            {errorDisplay}
            {helperDisplay}
          </FormGroup>
        )
      }

      return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
    }

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        <ControlLabelWithDebug />
        {selectControl}
        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  checkboxInput = ({ parsedValue, label, errorDisplay, helperDisplay, placeholder, ControlLabelWithDebug }) => {
    const { modelFieldPath, defaultAutoComplete } = this.state
    const props = this.getProps()
    const {
      readOnly,
      colProps,
      inArray,
      formGroupClassName = "",
      formGroupStyle = {},
      showPlaceholder = true,
      autoComplete = defaultAutoComplete,
    } = props
    const disabled = props.disabled ? true : false
    const numberId = getRandomInt()
    const placeholderLabel = showPlaceholder ? placeholder ?? label : null
    const placeholderLabelInArray = showPlaceholder ? placeholder : null

    if (readOnly) {
      if (inArray) {
        return (
          <>
            <CustomCheckbox
              numberId={numberId}
              label={placeholderLabelInArray}
              checked={parsedValue || false}
              disabled={true}
              autoComplete={autoComplete}
            />
            {errorDisplay}
            {helperDisplay}
          </>
        )
      }

      const formInput = (
        <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
          <ControlLabelWithDebug />
          <CustomCheckbox
            numberId={numberId}
            checked={parsedValue || false}
            disabled={true}
            label={React.isValidElement(placeholderLabel) ? "" : placeholderLabel}
            autoComplete={autoComplete}
          />
          {errorDisplay}
          {helperDisplay}
        </FormGroup>
      )

      return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
    }

    if (inArray) {
      return (
        <>
          <CustomCheckbox
            numberId={numberId}
            checked={parsedValue || false}
            disabled={disabled}
            onChange={this.handleChange}
            autoComplete={autoComplete}
            label={placeholderLabelInArray}
          />
          {errorDisplay}
          {helperDisplay}
        </>
      )
    }

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        <ControlLabelWithDebug />
        <CustomCheckbox
          numberId={numberId}
          label={React.isValidElement(placeholderLabel) ? "" : inArray ? "" : placeholderLabel}
          checked={parsedValue || false}
          disabled={disabled}
          onChange={this.handleChange}
          autoComplete={autoComplete}
        />
        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  /**
   * Avoid using this type of input because it has often accessibility issues.
   */
  switchInput = ({ parsedValue, label, errorDisplay, helperDisplay, ControlLabelWithDebug }) => {
    const { modelFieldPath, defaultAutoComplete } = this.state
    const props = this.getProps()
    const {
      readOnly,
      colProps,
      inArray,
      formGroupClassName = "",
      formGroupStyle = {},
      onText = "",
      offText = "",
      autoComplete = defaultAutoComplete,
    } = props
    const disabled = props.disabled ? true : false

    if (readOnly) {
      if (inArray) return <Switch label="" onText={onText} offText={offText} value={parsedValue || false} disabled={true} />

      const formInput = (
        <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
          <ControlLabelWithDebug />
          <Suspense fallback={null}>
            <Switch
              onText={onText}
              offText={offText}
              value={parsedValue || false}
              disabled={true}
              label={React.isValidElement(label) ? "" : label}
              wrapperClass="pull-right"
              autoComplete={autoComplete}
            />
          </Suspense>
        </FormGroup>
      )

      return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
    }

    if (inArray) {
      return (
        <Suspense fallback={null}>
          <Switch onText={onText} offText={offText} disabled={disabled} value={parsedValue} wrapperClass="pull-right" autoComplete={autoComplete} />
        </Suspense>
      )
    }

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        <ControlLabelWithDebug />
        <span
          className="switch-accessible-wrapper"
          onKeyDown={event => {
            const _value = { value: !parsedValue }
            handleAccessibleOnKeyDown({
              event,
              fn: () =>
                this.handleChange({
                  target: _value,
                  state: _value,
                }),
            })
          }}
        >
          <Suspense fallback={null}>
            <Switch
              label={React.isValidElement(label) ? "" : inArray ? "" : label}
              onText={onText}
              offText={offText}
              value={parsedValue || false}
              disabled={disabled}
              onChange={this.handleChange}
              wrapperClass="pull-right"
              autoComplete={autoComplete}
            />
          </Suspense>
        </span>
        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  radioInput = ({ parsedValue, label, ControlLabelWithDebug }) => {
    const { defaultAutoComplete } = this.state
    const props = this.getProps()
    const {
      field,
      option,
      readOnly,
      colProps,
      inArray,
      formGroupClassName = "",
      formGroupStyle = {},
      options,
      inline,
      displayMode,
      autoComplete = defaultAutoComplete,
    } = props
    const disabled = props.disabled ? true : false

    if (readOnly) {
      if (inArray) {
        return (
          <CustomRadio
            label=""
            value={parsedValue}
            disabled={true}
            name={field}
            option={option}
            options={options}
            inline={inline}
            displayMode={displayMode}
            autoComplete={autoComplete}
          />
        )
      }

      const formInput = (
        <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle}>
          <ControlLabelWithDebug />
          <CustomRadio
            label={label}
            value={parsedValue}
            disabled={true}
            name={field}
            option={option}
            options={options}
            inline={inline}
            displayMode={displayMode}
            autoComplete={autoComplete}
          />
        </FormGroup>
      )

      return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
    }

    const control = (
      <CustomRadio
        number={getRandomInt()}
        label={label}
        value={parsedValue}
        disabled={disabled}
        onClick={this.handleChange}
        name={field}
        option={option}
        options={options}
        inline={inline}
        autoComplete={autoComplete}
      />
    )

    if (inArray) return control

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle}>
        <ControlLabelWithDebug />
        {control}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  textAreaInput = ({ label, parsedValue, errorDisplay, helperDisplay, placeholder, ControlLabelWithDebug }) => {
    const { modelFieldPath, keyedValue, defaultAutoComplete } = this.state
    const props = this.getProps()
    const {
      fcClassName = "",
      readOnly,
      colProps,
      inArray,
      formGroupClassName = "",
      formGroupStyle = {},
      rows,
      minRows = 1,
      autoComplete = defaultAutoComplete,
      autoFocus,
    } = props
    const disabled = props.disabled ? true : false

    const inputValue = keyedValue ?? parsedValue ?? ""
    const control = (
      <FormControl
        id={modelFieldPath}
        aria-label={label}
        className={fcClassName}
        rows={rows ? rows : inputValue ? inputValue.split("\n").length : minRows}
        componentClass="textarea"
        bsClass="form-control"
        placeholder={placeholder}
        value={inputValue}
        disabled={disabled || readOnly}
        autoComplete={autoComplete}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        autoFocus={autoFocus}
        onKeyDown={this.handleKeyDown}
        onInput={({ target }) => {
          // 38px is the auto height (browser defined) and min-height of textarea
          // you can see that in the css variable in index.scss
          // We could retrieve it programmatically but that's a lot of hassle for no appreciable gain before a very long time.
          if (target.value) {
            // we need to assign twice the height to make the browser auto adjust the height
            target.style.height = "auto"
            target.style.height = target.scrollHeight + 1 + "px"
          } else {
            target.style.height = "38px"
          }
        }}
        onClick={event => setVirtualKeyboardChangeHandler(event, this.handleChange)}
      />
    )

    if (inArray) return control

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        <ControlLabelWithDebug />
        {control}
        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  numberIntegerInput = ({ label, parsedValue, errorDisplay, helperDisplay, placeholder, parsedType, ControlLabelWithDebug }) => {
    const { modelFieldPath, keyedValue, defaultAutoComplete } = this.state
    const props = this.getProps()
    const {
      fcClassName = "",
      readOnly,
      rightAddOn,
      linkTo,
      minimumFractionDigits,
      maximumFractionDigits,
      colProps,
      inArray,
      autoComplete = defaultAutoComplete,
      formGroupClassName = "",
      formGroupStyle = {},
      negative,
      action,
      actionClassName = "",
      actionIcon,
      actionTooltip = "",
      hideAddOn = false,
    } = props
    const disabled = props.disabled ? true : false
    const locale = getLocale()

    if (parsedValue || parsedValue === 0) {
      if (negative) parsedValue = -parsedValue
      if (parsedType === "integer") {
        parsedValue = Math.round(parsedValue)
      } else {
        parsedValue = formatDecimal(parsedValue, locale, { minimumFractionDigits, maximumFractionDigits })
      }
    }

    if (readOnly) {
      return this.readOnlyControlInArrayOrInForm({ control: parsedValue || parsedValue === 0 ? parsedValue : "", linkTo, helperDisplay })
    }

    if (parsedValue === 0) parsedValue = "0"
    const inputValue = keyedValue ?? parsedValue ?? ""

    if (inArray) {
      return (
        <>
          <FormControl
            id={modelFieldPath}
            aria-label={label}
            className={fcClassName}
            type="text"
            inputMode="numeric"
            bsClass="form-control"
            value={inputValue}
            disabled={disabled}
            autoComplete={autoComplete}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            onClick={event => setVirtualKeyboardChangeHandler(event, this.handleChange)}
          />
          {errorDisplay}
          {helperDisplay}
        </>
      )
    }

    const formControl = (
      <FormControl
        id={modelFieldPath}
        aria-label={label}
        className={fcClassName}
        type="text"
        inputMode="numeric"
        bsClass="form-control"
        placeholder={placeholder}
        autoComplete={autoComplete}
        value={inputValue}
        disabled={disabled}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}
        onClick={event => setVirtualKeyboardChangeHandler(event, this.handleChange)}
      />
    )

    const showInputGroup = action || rightAddOn

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        <ControlLabelWithDebug />
        {showInputGroup && (
          <InputGroup>
            {formControl}
            {action && !rightAddOn && !hideAddOn && (
              <InputGroup.Addon
                className={"form-input-addon c-pointer " + actionClassName}
                onClick={action}
                onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: action })}
              >
                <ButtonWithTooltip btnClassName="m-0 pd-0" bsStyle="default" className={actionIcon || "icn-icons icn-sm"} tooltip={actionTooltip} />
              </InputGroup.Addon>
            )}
            {rightAddOn && <InputGroup.Addon>{rightAddOn}</InputGroup.Addon>}
          </InputGroup>
        )}
        {!showInputGroup && formControl}
        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  percentageInput = ({ label, parsedValue, errorDisplay, helperDisplay, placeholder, ControlLabelWithDebug }) => {
    const { modelFieldPath, keyedValue, defaultAutoComplete } = this.state
    const props = this.getProps()
    const {
      hideAddOn = false,
      minimumFractionDigits,
      maximumFractionDigits,
      linkTo,
      fcClassName = "",
      readOnly,
      colProps,
      inArray,
      autoComplete = defaultAutoComplete,
      formGroupClassName = "",
      formGroupStyle = {},
      negative,
      action,
      actionClassName = "",
      actionIcon,
      actionTooltip = "",
      colorSign,
    } = props
    const locale = getLocale()
    const disabled = props.disabled ? true : false

    if (parsedValue || parsedValue === 0) {
      if (negative) parsedValue = -parsedValue
      parsedValue = formatPercentage(parsedValue, locale, { minimumFractionDigits, maximumFractionDigits })
      if (!readOnly) parsedValue = parsedValue.replace("%", "").replace(" ", "")
    }

    if (readOnly) {
      const addClassName = colorSign && this.props?.value < 0 ? "text-danger" : ""
      return this.readOnlyControlInArrayOrInForm({ control: parsedValue ?? "", linkTo, addClassName, helperDisplay })
    }
    if (parsedValue === 0) parsedValue = "0"

    const inputValue = keyedValue ?? parsedValue ?? ""
    const input = (
      <FormControl
        id={modelFieldPath}
        aria-label={label}
        className={fcClassName}
        type="text"
        bsClass="form-control"
        placeholder={placeholder}
        value={inputValue}
        disabled={disabled}
        autoComplete={autoComplete}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}
        onClick={event => setVirtualKeyboardChangeHandler(event, this.handleChange)}
      />
    )

    const formControl =
      hideAddOn && !action ? (
        input
      ) : (
        <InputGroup data-disabled={disabled}>
          {!action && !hideAddOn && <InputGroup.Addon className="form-input-addon">%</InputGroup.Addon>}
          {input}
          {action && (
            <InputGroup.Addon
              className={"form-input-addon c-pointer " + actionClassName}
              onClick={action}
              onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: action })}
            >
              <ButtonWithTooltip btnClassName="m-0 pd-0" bsStyle="default" className={actionIcon || "icn-icons icn-sm"} tooltip={actionTooltip} />
            </InputGroup.Addon>
          )}
        </InputGroup>
      )

    if (inArray) {
      return (
        <>
          {formControl}
          {errorDisplay}
          {helperDisplay}
        </>
      )
    }

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} data-disabled={disabled} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        <ControlLabelWithDebug />
        {formControl}
        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  currencyInput = ({ label, parsedValue, errorDisplay, helperDisplay, ControlLabelWithDebug }) => {
    const { modelFieldPath, keyedValue, defaultAutoComplete } = this.state
    const props = this.getProps()
    const {
      hideAddOn = false,
      minimumFractionDigits,
      maximumFractionDigits,
      linkTo,
      fcClassName = "",
      readOnly,
      colProps,
      inArray,
      autoComplete = defaultAutoComplete,
      formGroupClassName = "",
      formGroupStyle = {},
      rightAddOn,
      obj,
      currency = obj?.currency,
      symbolClassName = "",
      negative,
      action,
      actionClassName = "",
      actionIcon,
      actionTooltip = "",
      colorSign,
    } = props
    const locale = getLocale()
    const disabled = props.disabled ? true : false

    if (negative && parsedValue) parsedValue = -parsedValue

    if (readOnly) {
      const addClassName = colorSign && parsedValue < 0 ? "text-danger" : ""
      return this.readOnlyControlInArrayOrInForm({
        control:
          parsedValue || parsedValue === 0 ? formatCurrency(parsedValue, locale, { currency, minimumFractionDigits, maximumFractionDigits }) : "",
        linkTo,
        addClassName,
        helperDisplay,
      })
    }

    if (parsedValue === 0) parsedValue = "0"

    const _currency = currency || "EUR"
    let _parsedValue = formatCurrency(parsedValue, locale, {
      currency: _currency,
      minimumFractionDigits,
      maximumFractionDigits,
      currencyDisplay: "code",
    })
    if (_parsedValue && typeof _parsedValue === "string") _parsedValue = _parsedValue.replace(_currency, "").trim()

    const input = (
      <FormControl
        id={modelFieldPath}
        aria-label={label}
        className={fcClassName}
        type="text"
        inputMode="numeric"
        bsClass="form-control"
        disabled={disabled}
        value={keyedValue ?? _parsedValue ?? parsedValue ?? ""}
        autoComplete={autoComplete}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}
        onClick={event => setVirtualKeyboardChangeHandler(event, this.handleChange)}
      />
    )

    let formControl
    if (hideAddOn && !action) {
      formControl = input
    } else {
      formControl = (
        <InputGroup data-disabled={disabled}>
          {!hideAddOn && (
            <InputGroup.Addon className={"form-input-addon" + symbolClassName}>
              {getCurrencySymbol(locale, currency, { currencyDisplay: getConfigAtPath("currencyDisplay") })}
            </InputGroup.Addon>
          )}
          {input}
          {action && (
            <InputGroup.Addon
              className={"form-input-addon c-pointer " + actionClassName}
              onClick={action}
              onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: action })}
            >
              <ButtonWithTooltip btnClassName="m-0 pd-0" bsStyle="default" className={actionIcon || "icn-icons icn-sm"} tooltip={actionTooltip} />
            </InputGroup.Addon>
          )}
          {rightAddOn && <InputGroup.Addon>{rightAddOn}</InputGroup.Addon>}
        </InputGroup>
      )
    }

    if (inArray) {
      return (
        <>
          {formControl}
          {errorDisplay}
          {helperDisplay}
        </>
      )
    }

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} data-disabled={disabled} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        <ControlLabelWithDebug />
        {formControl}
        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )
    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  emailUsernameInput = ({ label, parsedValue, errorDisplay, helperDisplay, placeholder, ControlLabelWithDebug }) => {
    const { modelFieldPath, keyedValue, defaultAutoComplete } = this.state
    const props = this.getProps()
    const {
      linkTo,
      fcClassName = "",
      readOnly,
      colProps,
      formGroupClassName = "",
      formGroupStyle = {},
      inArray,
      autoComplete = defaultAutoComplete,
    } = props
    const disabled = props.disabled ? true : false

    if (readOnly) return this.readOnlyControlInArrayOrInForm({ control: parsedValue || "", linkTo, helperDisplay })

    const inputValue = keyedValue ?? parsedValue ?? ""
    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        {!inArray && <ControlLabelWithDebug />}
        <InputGroup data-disabled={disabled}>
          <InputGroup.Addon className="form-input-addon">
            <i className="icn-email-light icn-sm" />
          </InputGroup.Addon>
          {disabled ? (
            <div className="form-control" id={modelFieldPath} disabled>
              {inputValue}
            </div>
          ) : (
            <FormControl
              id={modelFieldPath}
              aria-label={label}
              className={applyClasses({ [fcClassName]: true })}
              type="email"
              autoComplete={autoComplete}
              bsClass="form-control"
              placeholder={placeholder}
              value={inputValue}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              onClick={event => setVirtualKeyboardChangeHandler(event, this.handleChange)}
            />
          )}
        </InputGroup>
        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  emailsInput = ({ parsedValue, errorDisplay, helperDisplay, ControlLabelWithDebug }) => {
    const { modelFieldPath, keyedValue, defaultAutoComplete } = this.state
    const props = this.getProps()
    const { readOnly, colProps, formGroupClassName = "", formGroupStyle = {}, select, autoComplete = defaultAutoComplete } = props
    const disabled = props.disabled ? true : false

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        <ControlLabelWithDebug />
        <CreatableSelect
          id={modelFieldPath}
          options={select}
          className="select-group"
          classNamePrefix="select"
          components={getSelectComponents({ isClearable: true })}
          formatCreateLabel={text => `${loc`Add`} "${text}"`}
          closeOnSelect={false}
          isMulti={true}
          value={(keyedValue ?? parsedValue ?? []).map(it => ({ value: it, label: it }))}
          autoComplete={autoComplete}
          onChange={this.handleChange}
          isDisabled={disabled || readOnly}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          aria-labelledby={modelFieldPath}
        />
        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  phoneInput = ({ label, parsedValue, errorDisplay, helperDisplay, placeholder, ControlLabelWithDebug }) => {
    const { modelFieldPath, keyedValue, defaultAutoComplete } = this.state
    const props = this.getProps()
    const {
      inline,
      fcClassName = "",
      readOnly,
      colProps,
      formGroupClassName = "",
      formGroupStyle = {},
      inArray,
      autoComplete = defaultAutoComplete,
    } = props
    const disabled = props.disabled ? true : false

    if (readOnly) {
      if (inline) {
        return (
          <Row>
            <Col xs={4} sm={3} md={2} lg={2}>
              <ControlLabelWithDebug clClassName="leading-3-5" />
            </Col>
            <Col xs={8} sm={9} md={10} lg={10}>
              <InputGroup data-disabled>
                <InputGroup.Addon className="form-input-addon">
                  <i className="icn-phone icn-xs" />
                </InputGroup.Addon>
                <div className="form-control" id={modelFieldPath} disabled>
                  {parsedValue || ""}
                </div>
              </InputGroup>
            </Col>
          </Row>
        )
      }

      const formInput = (
        <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle}>
          {!inArray && <ControlLabelWithDebug />}
          <InputGroup data-disabled>
            <InputGroup.Addon className="form-input-addon">
              <i className="icn-phone icn-xs" />
            </InputGroup.Addon>
            <div className="form-control" id={modelFieldPath} disabled>
              {parsedValue || ""}
            </div>
          </InputGroup>
        </FormGroup>
      )

      return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
    }
    const inputValue = keyedValue ?? parsedValue ?? ""
    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        {!inArray && <ControlLabelWithDebug />}
        <InputGroup data-disabled={disabled}>
          <InputGroup.Addon className="form-input-addon">
            <i className="icn-phone icn-xs" />
          </InputGroup.Addon>
          <FormControl
            id={modelFieldPath}
            aria-label={label}
            className={applyClasses({ [fcClassName]: true })}
            type="tel"
            inputMode="tel"
            pattern=".*[0-9]*"
            bsClass="form-control"
            placeholder={placeholder}
            disabled={disabled}
            value={inputValue}
            autoComplete={autoComplete}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            onClick={event => setVirtualKeyboardChangeHandler(event, this.handleChange)}
          />
        </InputGroup>
        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  ibanInput = ({ label, parsedValue, errorDisplay, helperDisplay, placeholder, ControlLabelWithDebug }) => {
    const { modelFieldPath, keyedValue, defaultAutoComplete } = this.state
    const props = this.getProps()
    const {
      fcClassName = "",
      readOnly,
      colProps,
      formGroupClassName = "",
      formGroupStyle = {},
      action,
      actionClassName = "",
      actionIcon,
      actionTooltip = "",
      autoComplete = defaultAutoComplete,
      inArray,
      help,
      showFormattedIban = false,
    } = props

    if (readOnly && !showFormattedIban) return this.readOnlyControlInArrayOrInForm({ control: parsedValue ?? "", helperDisplay })

    const input = (
      <FormControl
        id={modelFieldPath}
        aria-label={label}
        className={fcClassName}
        type="text"
        bsClass="form-control"
        placeholder={placeholder}
        value={showFormattedIban ? formatIban(keyedValue ?? parsedValue ?? "") : keyedValue ?? parsedValue ?? ""}
        autoComplete={autoComplete}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        readOnly={readOnly}
        onClick={event => setVirtualKeyboardChangeHandler(event, this.handleChange)}
      />
    )

    const copyButton = showFormattedIban && (
      <InputGroup.Addon
        className={"c-pointer"}
        onClick={() => copyToClipboard(parsedValue)}
        tabIndex="0"
        onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: () => copyToClipboard(unformatIban(parsedValue)) })}
      >
        <ButtonWithTooltip btnClassName="m-0 pd-0" bsStyle="default" className="icn-copy icn-sm" tooltip={actionTooltip} />
      </InputGroup.Addon>
    )

    const formControl = action ? (
      <InputGroup>
        <InputGroup.Addon
          className={(!showFormattedIban && "form-input-addon ") + "c-pointer " + actionClassName}
          onClick={action}
          onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: action })}
        >
          <ButtonWithTooltip btnClassName="m-0 pd-0" bsStyle="default" className={actionIcon || "icn-icons icn-sm"} tooltip={actionTooltip} />
        </InputGroup.Addon>
        {input}
        {copyButton}
      </InputGroup>
    ) : (
      <InputGroup>
        {input}
        {copyButton}
      </InputGroup>
    )

    if (inArray) {
      return (
        <>
          <DebugPopover debug={debug} help={help} modelFieldPath={modelFieldPath} />
          {formControl}
          {errorDisplay}
          {helperDisplay}
        </>
      )
    }

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        <ControlLabelWithDebug />
        {formControl}
        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  noticeInput = ({ ControlLabelWithDebug }) => {
    const props = this.getProps()
    const { colProps, formGroupClassName = "", formGroupStyle = {} } = props

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName} notice`} style={formGroupStyle}>
        <ControlLabelWithDebug />
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  textPasswordInput = ({ label, parsedValue, errorDisplay, helperDisplay, placeholder, parsedType, ControlLabelWithDebug }) => {
    const { modelFieldPath, keyedValue, defaultAutoComplete } = this.state
    const props = this.getProps()
    const {
      linkTo,
      rightAddOn,
      readOnly,
      colProps,
      fcClassName = "",
      formGroupClassName = "",
      formGroupStyle = {},
      autoComplete = defaultAutoComplete,
      inArray,
      action,
      actionClassName = "",
      actionIcon,
      actionTooltip = "",
      help,
      // Main use case is when the app is in a business-working language but users have to write things in their original spelling.
      // For instance the app might be set to French but the user has to write the names of customers in Arabic.
      rtl,
      name,
      autoFocus,
      inputRef,
    } = props
    const disabled = props.disabled ? true : false
    if (readOnly) {
      return this.readOnlyControlInArrayOrInForm({ control: typeof parsedValue === "number" ? parsedValue : loc(parsedValue), linkTo, helperDisplay })
    }

    const inputValue = keyedValue ?? parsedValue ?? ""
    const control = (
      <FormControl
        id={modelFieldPath}
        aria-label={label}
        inputRef={inputRef}
        className={fcClassName + `${rtl ? " direction-rtl" : ""}`}
        type={parsedType}
        bsClass="form-control"
        value={inputValue}
        placeholder={placeholder}
        disabled={disabled}
        onChange={this.handleChange}
        onClick={event => setVirtualKeyboardChangeHandler(event, this.handleChange)}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}
        autoComplete={parsedType === "password" ? autoComplete || "new-password" : autoComplete}
        name={name}
        autoFocus={autoFocus}
      />
    )

    const input = action ? (
      <InputGroup>
        {control}
        <InputGroup.Addon
          className={"form-input-addon c-pointer " + actionClassName}
          onClick={action}
          onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: action })}
        >
          <ButtonWithTooltip btnClassName="m-0 pd-0" bsStyle="default" className={actionIcon || "icn-icons icn-sm"} tooltip={actionTooltip} />
        </InputGroup.Addon>
      </InputGroup>
    ) : rightAddOn ? (
      <InputGroup>
        {control}
        <InputGroup.Addon>{rightAddOn}</InputGroup.Addon>
      </InputGroup>
    ) : (
      control
    )

    if (inArray) {
      return (
        <>
          <DebugPopover debug={debug} help={help} modelFieldPath={modelFieldPath} />
          {input}
          {errorDisplay}
          {helperDisplay}
        </>
      )
    }

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        <ControlLabelWithDebug />
        {input}
        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  colorInput = ({ parsedValue, parsedType, ControlLabelWithDebug, errorDisplay, helperDisplay }) => {
    const { keyedValue, defaultAutoComplete } = this.state
    const props = this.getProps()
    const { formGroupClassName = "", formGroupStyle, colProps, readOnly, inArray, autoComplete = defaultAutoComplete } = props
    const formControlClassName = `form-control${inArray ? " in-array" : ""}`

    const control = parsedValue ? (
      <InputGroup>
        <input
          type={parsedType}
          className={formControlClassName}
          value={keyedValue ?? parsedValue ?? ""}
          autoComplete={autoComplete}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          disabled={readOnly}
        />
        {!readOnly && (
          <InputGroup.Addon className="select-btn c-pointer" onClick={() => this.handleChange({ target: { value: null } })}>
            {/* this svg is the same as the one from react-select */}
            <svg height="19" width="19" viewBox="0 0 19 19">
              <path
                fill="currentColor"
                d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
              ></path>
            </svg>
          </InputGroup.Addon>
        )}
      </InputGroup>
    ) : (
      <div
        className={`${formControlClassName} color-input-no-color`}
        disabled={readOnly}
        onClick={() => !readOnly && this.handleChange({ target: { value: "#000000" } })}
      ></div>
    )

    if (inArray) {
      return (
        <>
          {control}
          {errorDisplay}
          {helperDisplay}
        </>
      )
    }

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle}>
        <ControlLabelWithDebug />
        {control}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  // These 4 drag handles could be merged with the ones in DocumentComponent
  // or DocumentComponent could use this component with type file.
  handleDragEnter = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  handleDragLeave = event => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ showDropBox: false })
  }

  handleDragOver = event => {
    event.preventDefault()
    event.stopPropagation()
    event.dataTransfer.dropEffect = "copy"
    this.setState({ showDropBox: true })
  }

  handleDragDrop = (event, { accept, maxDocumentSize }) => {
    // The accept attribute still allows the user to select any type of file
    // in the select dropdown, so it is unreliable when clicking on the button.
    // When drag and dropping the accept attribute is ignored, so we must validate
    // ourself the types. This is not an easy task, far from perfect, because the format
    // of the declaration is rich https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept#unique_file_type_specifiers
    event.preventDefault()
    event.stopPropagation()

    if (accept) {
      const { files } = event.dataTransfer
      event.target.files = files
      this.handleFileControlClick(event, { actionType: actionTypes.SELECT_FILE, accept, maxDocumentSize })
    } else {
      // kind of hacky
      event.target = event.dataTransfer
    }

    this.setState({ showDropBox: false })
    this.handleChange(event)
  }

  validateUploadFiles = ({ files, accept = "", maxDocumentSize }) => {
    accept = accept.split(",").map(accept => accept.replace(".", "").trim())

    let maxSizeNumber

    if (maxDocumentSize && typeof maxDocumentSize === "string" && maxDocumentSize.toUpperCase().includes("MB")) {
      maxSizeNumber = parseFloat(maxDocumentSize.substring(0, maxDocumentSize.length - 2)) * 1024 * 1024
    }
    const acceptedFiles = []
    for (let i = 0; i < files?.length; i++) {
      const file = files[i]
      const splittedFileName = file.name.split(".") || []
      const fileType = splittedFileName.length > 1 ? splittedFileName.pop().toLowerCase() : file.type

      if (maxSizeNumber && file?.size > maxSizeNumber) {
        file.errorFormat = `${loc("File too large")}. ${loc("Max size")} ${maxDocumentSize}`
      } else if (accept?.[0] && !accept.find(accept => fileType.startsWith(accept) || fileType.endsWith(accept))) {
        file.errorFormat = `${loc("Forbidden document extension")} "${fileType}". ${loc("Accepted formats")}: ${accept}`
      } else {
        delete file.errorFormat
      }
      acceptedFiles.push(file)
    }
    return acceptedFiles
  }

  clickOnFileInput(event, { inputId }) {
    event.stopPropagation()
    document.querySelector(`#${inputId}`)?.click()
  }

  handleFileControlClick = async (event, { value, file, actionType, accept, maxDocumentSize }) => {
    const props = this.getProps()
    const { action, entityName, entityRegistration, fileType } = props

    /**
     * This input type is very special because the saving of files is not in the data model.
     * This component handles the saving / delete operations if entityName, entityRegistration and fileType
     * are provided. Otherwise it is up to the scriptor to handle them by using the action props.
     * Cases where the scriptor would decide to handle them is when the file must not be immediately
     * saved / deleted, but rather another user action would be needed, like clicking on a save button.
     */
    const target = event.currentTarget // we need to save a reference of the original target

    if (typeof action === "function") {
      event.actionType = actionType
      event.filename = file?.name
      await action(event)
    }

    if (actionType === actionTypes.VIEW_FILE) {
      if (file._id) {
        // The file comes from our platform because it has an _id.
        // The entityName and entityRegistration cannot be passed through props in this case,
        // we rely on what has been saved only.
        const { data: blob } = await axios.get(`${getEntityServerRoute(file.entityName)}/${file.entityRegistration}/documents/${file._id}/content`, {
          responseType: "blob",
        })
        openDocInTab({ blob, docName: file.name })
      } else {
        openDocInTab({ base64: await fileToBase64(file, { removeBase64Prefix: false }), docName: file.name })
      }
    }

    if (actionType === actionTypes.DELETE_FILE) {
      if (entityName && entityRegistration && file._id) {
        await axios.delete(`${getEntityServerRoute(entityName)}/${entityRegistration}/documents/${file._id}`)
      }
      this.handleChange({ target: { files: value.filter(_file => _file.name !== file.name) } })
    }

    if (actionType === actionTypes.SELECT_FILE) {
      this.validateUploadFiles({ files: target.files, accept, maxDocumentSize })

      if (entityName && entityRegistration && fileType) {
        for (let i = 0; i < target.files.length; i++) {
          const file = target.files[i]
          if (file.errorFormat) continue

          const formData = new FormData()
          formData.append("file", file)
          formData.append("type", fileType)
          formData.append("name", file.name)
          file.entityName = entityName
          file.entityRegistration = entityRegistration

          await axios
            .post(`${getEntityServerRoute(entityName)}/${entityRegistration}/documents`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then(({ data }) => (file._id = data._id))
            .catch(e => (file.errorFormat = e.response?.data?.message || e.message))
        }
      }
      this.handleChange({ target })
      target.value = null // this is necessary to allow selecting again a same file
    }
  }

  fileInput = ({ parsedType, parsedValue, errorDisplay, helperDisplay, ControlLabelWithDebug }) => {
    const { keyedValue, modelFieldPath, showDropBox } = this.state
    const props = this.getProps()
    const {
      formGroupClassName = "",
      formGroupStyle,
      colProps,
      // the default size is small (sm), like for buttons in FormContent
      btnClassName = "btn-sm btn-primary btn-fill",
      multiple,
      deleteText,
      deleteClassName = "text-danger",
      deleteIcon = "icn-xmark",
      viewText,
      viewClassName = "",
      viewIcon = "icn-eye",
      accept,
      btnText,
      iconClassName,
      maxDocumentSize,
      showNoFileHint,
      dropAreaText,
      subText,
      readOnly,
      disabled,
    } = props

    const value = keyedValue || (parsedValue ? (Array.isArray(parsedValue) ? parsedValue : [parsedValue]) : undefined)
    const canModify = !readOnly && !disabled
    const inputId = modelFieldPath

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle}>
        <ControlLabelWithDebug />

        <div
          className="form-input-file-drop-area c-pointer"
          data-show-drop-area-hint={canModify && showDropBox}
          onDrop={event => canModify && this.handleDragDrop(event, { accept, maxDocumentSize })}
          onDragOver={event => canModify && this.handleDragOver(event)}
          onDragEnter={event => canModify && this.handleDragEnter(event)}
          onDragLeave={event => canModify && this.handleDragLeave(event)}
          onClick={event => canModify && this.clickOnFileInput(event, { inputId, accept })}
        >
          <button
            type="text"
            disabled={!canModify}
            className={"btn " + btnClassName}
            onClick={event => this.clickOnFileInput(event, { inputId, accept })}
          >
            {iconClassName && <i className={iconClassName} />}
            {btnText ? loc(btnText) : multiple ? loc("Files") : loc("File")}
          </button>

          {dropAreaText && <div className="form-input-file-drop-area-text">{dropAreaText}</div>}

          <input
            id={inputId}
            type={parsedType}
            accept={accept}
            onChange={event => this.handleFileControlClick(event, { value, actionType: actionTypes.SELECT_FILE, accept, maxDocumentSize })}
            style={{ hidden: true }}
            multiple={multiple}
          />
        </div>

        {subText && <div className="form-input-subtext">{subText}</div>}

        {value && (
          <div className="form-input-files mt-5px">
            {(value || []).length === 0 && showNoFileHint && <div className="file-input-no-file-hint font-weight-bold mt-5px">{loc("No file")}</div>}

            {value?.map(
              (file, index) =>
                !errorDisplay && (
                  <div key={index} className="form-input-file mt-5px">
                    <div className="form-input-filename font-weight-bold">{file.name}</div>
                    <div className="form-input-file-controls white-space-nowrap">
                      <span
                        className={"c-pointer ml-10px " + viewClassName}
                        onClick={event => this.handleFileControlClick(event, { value, file, actionType: actionTypes.VIEW_FILE, accept })}
                      >
                        {viewText && <span className="underline">{viewText}</span>}
                        {viewIcon && <i className={viewIcon + " icn-xs"} />}
                      </span>
                      {canModify && (
                        <span
                          className={"c-pointer ml-10px " + deleteClassName}
                          onClick={event => this.handleFileControlClick(event, { value, file, actionType: actionTypes.DELETE_FILE, accept })}
                        >
                          {deleteText && <span className="underline">{deleteText}</span>}
                          {deleteIcon && <i className={deleteIcon + " icn-xs"} />}
                        </span>
                      )}
                    </div>
                  </div>
                ),
            )}
          </div>
        )}

        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  sliderInput = ({ ControlLabelWithDebug }) => {
    const { modelFieldPath } = this.state
    const props = this.getProps()
    const { formGroupClassName = "", formGroupStyle, readOnly, colProps, sliderOptions, disabled } = props
    const {
      start = 0,
      step = 10,
      pipsValues = [],
      hidePipsValues,
      tooltips = false,
      pipsMode = "values",
      pipsStepped = true,
      pipsDensity = 100,
      connect = "lower",
      snap = true,
    } = sliderOptions || {}

    const onSlide = values => this.handleChange({ target: { value: values[0] } })

    const pipsValuesMin = pipsValues[0]
    const pipsValuesMax = pipsValues[pipsValues.length - 1]
    const pipsValuesDiff = pipsValuesMax - pipsValuesMin
    const sliderRange = {}
    for (let i = pipsValuesMin; i <= pipsValuesMax; i += step) {
      if (i === pipsValuesMin) {
        sliderRange.min = pipsValuesMin
      } else if (i === pipsValuesMax) {
        sliderRange.max = pipsValuesMax
      } else {
        sliderRange[`${((i - pipsValuesMin) / pipsValuesDiff) * 100}%`] = i
      }
    }

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle}>
        <ControlLabelWithDebug />
        <Slider
          id={modelFieldPath}
          start={start}
          step={step}
          connect={connect}
          snap={snap}
          tooltips={tooltips}
          range={sliderRange}
          hidePipsValues={hidePipsValues}
          pips={{
            mode: pipsMode,
            stepped: pipsStepped,
            density: pipsDensity,
            values: pipsValues,
          }}
          onSlide={onSlide}
          disabled={disabled || readOnly}
        />
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  markdownInput = ({ errorDisplay, helperDisplay, placeholder, parsedValue }) => {
    const { keyedValue, modelFieldPath, renderMarkdown: renderMarkdownState, defaultAutoComplete } = this.state
    const props = this.getProps()
    const {
      renderMarkdown: renderMarkdownProp,
      handleMarkdownRenderToggle,
      fcClassName = "",
      formGroupClassName = "",
      formGroupStyle = {},
      readOnly,
      colProps,
      autoComplete = defaultAutoComplete,
      inArray,
      rows,
      minRows = 1,
      label,
    } = props
    const renderMarkdown = renderMarkdownProp ?? renderMarkdownState
    const inputValue = keyedValue ?? parsedValue ?? ""

    const control =
      readOnly || renderMarkdown ? (
        <div
          className={"form-input-markdown pd-theme " + fcClassName}
          dangerouslySetInnerHTML={{ __html: inputValue && sanitize(marked(inputValue)) }}
        />
      ) : (
        // this component is almost the same as textarea
        <FormControl
          id={modelFieldPath} // should be unique
          aria-label={label}
          className={fcClassName}
          rows={rows ? rows : inputValue ? inputValue.split("\n").length : minRows}
          componentClass="textarea"
          bsClass="form-control"
          placeholder={placeholder}
          value={inputValue}
          disabled={readOnly}
          autoComplete={autoComplete}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          onInput={({ target }) => {
            // 38px is the auto height (browser defined) and min-height of textarea
            // you can see that in the css variable in index.scss
            // We could retrieve it programmatically but that's a lot of hassle for no appreciable gain before a very long time.
            if (target.value) {
              // we need to assign twice the height to make the browser auto adjust the height
              target.style.height = "auto"
              target.style.height = target.scrollHeight + 1 + "px"
            } else {
              target.style.height = "38px"
            }
          }}
          onClick={event => setVirtualKeyboardChangeHandler(event, this.handleChange)}
        />
      )

    if (inArray) return control

    const formInput = (
      <FormGroup bsClass={`form-group ${formGroupClassName}`} style={formGroupStyle} data-model-field-path={modelFieldPath}>
        {label && (
          <label className="control-label">
            {label}

            {!readOnly && (
              <i
                className={"ml-5px icn-xs max-h-14px c-pointer text-success " + (renderMarkdown ? "icn-edit" : "icn-eye")}
                onClick={() => {
                  if (renderMarkdownProp !== undefined && handleMarkdownRenderToggle) {
                    handleMarkdownRenderToggle()
                  } else {
                    this.setState({ renderMarkdown: !renderMarkdown })
                  }
                  setTimeout(() => {
                    const formInputElement = document.getElementById(modelFieldPath)
                    if (formInputElement) {
                      formInputElement.style.height = "auto"
                      formInputElement.style.height = formInputElement.scrollHeight + 1 + "px"
                    }
                  })
                }}
              />
            )}
          </label>
        )}

        {control}

        {errorDisplay}
        {helperDisplay}
      </FormGroup>
    )

    return colProps ? <Col {...colProps}>{formInput}</Col> : formInput
  }

  checkMinMax({ type, value, min, max, obj, currency }) {
    const _isLessThanMin = isLessThanMin({ value, min })
    const _isMoreThanMax = isMoreThanMax({ value, max })

    if (!_isLessThanMin && !_isMoreThanMax) return

    const locale = getLocale()
    currency = currency || obj?.currency

    const minOrMax = _isLessThanMin ? min : max

    // In some cases, instanceof Date might not return true even if it is an actual Date object
    const isDate = ["date", "datetime"].includes(type) || minOrMax instanceof Date || Object.prototype.toString.call(minOrMax) === "[object Date]"

    let formattedMinOrMax = minOrMax
    if (type === "currency") formattedMinOrMax = formatCurrency(minOrMax, locale, currency)
    else if (type === "percentage") formattedMinOrMax = formatPercentage(minOrMax, locale)
    else if (isDate) formattedMinOrMax = type === "datetime" ? formatDateTime(minOrMax, locale) : formatDate(minOrMax, locale)

    const symbol = _isLessThanMin ? "≥" : "≤"
    return loc`Must be` + ` ${symbol} ${formattedMinOrMax}`
  }

  render() {
    const { field, obj = {}, noLodash } = this.props
    const props = this.getProps()
    const {
      value,
      type,
      hidden,
      select,
      mandatory,
      touched,
      warningFormat: propsWarningFormat,
      errorFormat,
      errorHint,
      min,
      max,
      currency,
      helper,
    } = props
    const { warningFormat = propsWarningFormat, keyedValue, modelFieldPath } = this.state

    if (hidden) return null

    let parsedValue
    if (!isEmpty(value)) parsedValue = value
    else if (field) parsedValue = field.includes(".") && !noLodash ? get(obj, field) : obj?.[field]

    // some comfort code - if we have a date but type is not set to date we will return an object and React will shoot some cabalistic errors.
    let parsedType = type || "text"
    if (!parsedType && parsedValue instanceof Date) parsedType = "date"
    parsedType = parsedType?.toLowerCase()
    if (parsedType === "string") parsedType = "text"

    let errorMessage = mandatory && touched && isEmpty(parsedValue) ? getMandatoryLabel(this.getLabel()) : errorFormat
    if (!errorMessage && !isEmpty(parsedValue)) errorMessage = this.checkMinMax({ type: parsedType, value: parsedValue, min, max, obj, currency })

    let errorDisplay
    if (errorMessage || warningFormat) {
      const message = errorMessage || warningFormat
      errorDisplay = (
        <span className={errorMessage ? "validation-error" : "validation-warning"}>
          {typeof message === "string" ? `${loc(message)} ${errorHint || ""}` : Array.isArray(message) ? loc(...message) : null}
        </span>
      )
    }

    const inputParams = {
      parsedValue,
      parsedType,
      label: this.getLabel(),
      placeholder: this.getPlaceholder(),
      errorDisplay,
      helperDisplay: getHelperDisplay({ helper }),
      ControlLabelWithDebug: this.getControlLabelWithDebug,
    }

    if (parsedType === "datetime") return this.datetimeInput(inputParams)
    if (parsedType === "date") return this.dateInput(inputParams)
    if (select && parsedType !== "emails") return this.selectInput(inputParams)
    if (parsedType === "checkbox") return this.checkboxInput(inputParams)
    if (parsedType === "radio") return this.radioInput(inputParams)
    if (parsedType === "switch") return this.switchInput(inputParams)
    if (parsedType === "textarea") return this.textAreaInput(inputParams)
    if (parsedType === "number" || parsedType === "integer") return this.numberIntegerInput(inputParams)
    if (parsedType === "percentage") return this.percentageInput(inputParams)
    if (parsedType === "currency") return this.currencyInput(inputParams)
    if (["email", "username"].includes(parsedType)) return this.emailUsernameInput(inputParams)
    if (parsedType === "emails") return this.emailsInput(inputParams)
    if (parsedType === "phone") return this.phoneInput(inputParams)
    if (parsedType === "iban") return this.ibanInput(inputParams)
    if (parsedType === "notice") return this.noticeInput(inputParams)
    if (["text", "password"].includes(parsedType)) return this.textPasswordInput(inputParams)
    if (parsedType === "color") return this.colorInput(inputParams)
    if (parsedType === "file") return this.fileInput(inputParams)
    if (parsedType === "slider") return this.sliderInput(inputParams)
    if (parsedType === "markdown") return this.markdownInput(inputParams)
    if (parsedType === "richtext") {
      return (
        <Suspense fallback={null}>
          <FormInputRichText
            {...inputParams}
            getProps={this.getProps}
            handleChange={this.handleChange}
            keyedValue={keyedValue}
            modelFieldPath={modelFieldPath}
          />
        </Suspense>
      )
    }

    return <div className="text-danger">{`Unknown type "${parsedType}"`}</div>
  }
}

function filterOptionFunction(option, typedValue = "") {
  const optVal = removeAllAccents(option.label || option.value)
  if (!optVal) return true
  if (typedValue === null) typedValue = ""

  const typedValues = removeAllAccents(typedValue).replace(/\\/g, "").split(" ")
  for (const val of typedValues) {
    try {
      if (optVal.toString().search(new RegExp(val, "i")) === -1) return false
    } catch (error) {
      console.error("FormInput.filterOption.error", error)
      return false
    }
  }
  return true
}

export default FormInput

export function getMandatoryLabel(label) {
  if (typeof label === "string" && label) return loc`${label} is mandatory`
  return loc`Field is mandatory`
}

export function updateFieldProps(patch, { obj, field, mandatory, errorFormat }) {
  const fieldProps = getFieldProps(obj, field)

  let updateFieldProps

  // Sync "this.props.mandatory" with "obj.props_{field}.mandatory"
  if (mandatory && !fieldProps.mandatory) {
    fieldProps.mandatory = true
    updateFieldProps = true
  }

  // Touch field if not yet touched
  if (!fieldProps.touched) {
    fieldProps.touched = true
    updateFieldProps = true
  }

  // Reset field warningFormat
  updateFieldProps = updateFieldProps || !!fieldProps.warningFormat
  fieldProps.warningFormat = null

  // Set field errorFormat
  if (errorFormat || (!errorFormat && fieldProps.errorFormat)) {
    fieldProps.errorFormat = errorFormat
    updateFieldProps = true
  }

  if (updateFieldProps) setFieldProps(patch, field, fieldProps)
}

export function getSelectComponents({
  isClearable = true,
  showCopy = false,
  hideChevron,
  multiple,
  isLoading,
  handleClear,
  handleNegativeValues,
  selectedValues,
} = {}) {
  const selectComponents = {
    MultiValueRemove: props => {
      const { isDisabled } = props.selectProps || {}
      return !isDisabled && <components.MultiValueRemove {...props} />
    },
    IndicatorsContainer: props => {
      const { isDisabled, getValue } = props
      const selectValues = getValue() || []

      let values, labels
      for (let i = 0; i < selectValues.length; i++) {
        const { label, value } = selectValues[i]
        labels = `${labels ? `${labels}, ` : ""}${label || ""}`
        values = `${values ? `${values}, ` : ""}${value || ""}`
      }

      // The size of these buttons must meet the AA accessibility level
      // meaning their size must be at least 24px x 24px

      return (
        <components.IndicatorsContainer {...props}>
          {values !== undefined && !isDisabled && showCopy && (
            <ButtonWithTooltip
              className="icn-copy icn-xs select-btn"
              btnClassName="m-0 pd-0 flex-center min-w-24px min-h-24px"
              onClick={() => copyToClipboard(labels || values)}
            />
          )}

          {isClearable && values !== undefined && !isDisabled && (
            <ButtonWithTooltip
              data-test="empty-input-icon-btn"
              className="icn-xmark icn-xs select-btn"
              btnClassName="m-0 pd-0 flex-center min-w-24px min-h-24px"
              onClick={event => {
                event?.preventDefault()
                event?.stopPropagation()
                const setValue = handleClear || props.setValue
                setValue(multiple ? [] : null)
              }}
            />
          )}

          {isLoading && <i className="icn-circle-notch icn-xs icn-spin text-gray" />}

          {!hideChevron && !isDisabled && (
            <ButtonWithTooltip
              className="icn-chevron-down icn-xs select-btn"
              btnClassName="m-0 pd-0 flex-center min-w-24px min-h-24px"
              tooltip="Select"
            />
          )}
        </components.IndicatorsContainer>
      )
    },
  }

  if (handleNegativeValues) {
    selectComponents.Option = props => (
      <components.Option {...props}>
        <span data-select-option-value={props.data.value}>{props.data.label}</span>
        <ButtonWithTooltip
          className="icn-minus icn-xxs"
          btnClassName="c-pointer m-0 pdt-0 text-black-lightest min-h-fit"
          tooltip={loc("Negative filter")}
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            props.setValue([...selectedValues, { ...props.data, value: `-${props.data.value}` }])
          }}
        />
      </components.Option>
    )
  }

  return selectComponents
}

/**
 * For now string only, can be evolved into an object helper: { text: "" }
 */
export function getHelperDisplay({ helper }) {
  let helperDisplay = null
  if (typeof helper === "string") {
    helperDisplay = <div className="form-input-helper" dangerouslySetInnerHTML={{ __html: sanitize(marked(helper)) }} />
  }
  if (React.isValidElement(helper)) {
    helperDisplay = <div className="form-input-helper">{helper}</div>
  }
  return helperDisplay
}
